// import DropPanel from './components/DropPanel';
// import MainMenu  from './components/MainMenu';

// Init
import inputAnimate from './components/inputAnim';
import accordion    from './components/accodrion';
import burger       from './components/MainMenu';
import popupsFunc   from './components/modals';

function init() {
	inputAnimate();
	accordion();
	burger();
	popupsFunc.init();
}

(function () {
	const tabsBtn     = document?.querySelectorAll('.tabs__btn');
	const tabsContent = document?.querySelectorAll('.tabs-content');

	function hideTabsContent() {
		tabsContent.forEach((item) => {
			item.classList.remove('active');
		});

		tabsBtn.forEach((item) => {
			item.classList.remove('active');
		});
	}

	function showTabsContent(i = 0) {
		tabsContent[i]?.classList.add('active');
		tabsBtn[i]?.classList.add('active');
	}

	hideTabsContent();
	showTabsContent();

	document.querySelector('.tabs__list')?.addEventListener('click', (event) => {
		const { target } = event;

		if (target && target.classList.contains('tabs__btn')) {
			tabsBtn.forEach((item, i) => {
				if (target == item) {
					hideTabsContent();
					showTabsContent(i);
				}
			});
		}
	});
	init();
}());

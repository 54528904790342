import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const burger = () => {
	const burgerEl      = document?.querySelector('[data-burger]');
	const menu          = document?.querySelector('[data-menu]');
	const targetElement = document.querySelector('body');
	const close         = document?.querySelector('[data-close]');
	const overlay       = document?.querySelector('[data-overlay]');

	burgerEl?.addEventListener('click', () => {
		burgerEl?.classList.toggle('burger--active');
		menu?.classList.add('active');
		overlay?.classList.add('active');
		disableBodyScroll(targetElement);
	});

	close?.addEventListener('click', () => {
		burgerEl?.classList.remove('burger--active');
		menu?.classList.remove('active');
		overlay?.classList.remove('active');
		enableBodyScroll(targetElement);
	});

	overlay.addEventListener('click', (e) => {
		if (e.currentTarget === e.target) {
			menu?.classList.remove('active');
			overlay?.classList.remove('active');
			enableBodyScroll(targetElement);
		}
	});

	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty('--app-height', `${window.innerHeight}px`);
	};
	window.addEventListener('resize', appHeight);
	appHeight();
};

export default burger;

const inputAnimate = () => {
	const inputs = document?.querySelectorAll('input');
	inputs.forEach((input) => {
		input.addEventListener('input', () => {
			if (input.value) {
				input.classList.add('form__input--active');
			} else {
				input.classList.remove('form__input--active');
			}
		});
	});
};

export default inputAnimate;
